.contentPage h2 {
  font-style: normal;
  font-weight: bold;
  color: black;
  font-size: 28px;
  background-color: rgba(244, 211, 94, 0.3);
  font-weight: 700;
  width: auto;
  line-height: 50px;
  letter-spacing: -0.165px;
  margin: 30px auto 10px;
  color: rgba(58, 59, 59, 1);
  border-radius: 5px;
  display: block;
  text-align: center;
}

.contentPage h3 img {
  vertical-align: middle;
  margin-right: 0.6em;
}

.contentPage a,
.contentPage a:link,
.contentPage a:active,
.contentPage a:visited {
  color: rgba(60, 163, 121, 1);
}

.Homepage a {
  transition: font-size 0.2s;
}

.Homepage a:hover {
  font-size: 1.4rem;
}

#Homepage-down-arrow {
  font-size: 0;
}

#Homepage-down-arrow:hover {
  font-size: 0;
}

#Homepage-down-arrow img {
  transition: 0.2s;
  width: 30px;
}

#Homepage-down-arrow:hover img {
  width: 33px;
}


/* #subscribe-form-submit {
  height: 20.8px;
  font-size: 18px;
} */
/* #subscribe-form-submit:hover {
  transition: font-size 0.2s;
  font-size: 22px;
} */
/* OPTIMISED FOR PHONES */
@media screen and (max-height: 850px) and (max-width: 451px) {
  .contentPage h2 {
    margin: 0px 0px 10px 0px;
    font-size: 28px;
  }
}