.beAnActivist {
  font-family: "Nunito Sans";
  background-color: rgba(60, 163, 121, 1);
  color: white;
  width: 100%;
  font-weight: bold;
  font-size: 38px;
  height: 50px;
  font-weight: 900;
  line-height: 50px;
  letter-spacing: -0.165px;
  margin: 20px 0px;
  border-radius: 2.5px;
  text-align: center;
}

.beAnActivistImg {
  border: 1px solid rgba(58, 59, 59, 0.3);
  box-sizing: border-box;
  border-radius: 5px;
  max-width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

/* OPTIMISED FOR PHONES */
@media screen and (max-height: 850px) and (max-width: 451px) {
  .beAnActivist {
    margin: 10px 0px;
  }
}
