.ProviderDetails div>ul {
  display: flex;
  padding-left: 0;
}

.ProviderDetails div>ul>li {
  display: block;
  margin-right: 1%;

  background: #35ad7b33;

  padding: 0 30px 40px 30px;
  border-radius: 15px;
  font-size: 19px;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 10%);
}

.ProviderDetails div>ul>li h2 {
  padding-top: 0 !important;
  padding-left: 0 !important;
  background: transparent !important;
}

.ProviderDetails div>ul>li>ul {
  padding-left: 20px;
}

.ProviderDetails div>ul>li li {
  list-style-type: disc;
  padding-left: 0;
}

.ProviderDetailsdiv>ul>li+li {
  display: block;
  margin-right: 0;
  margin-left: 1%;
  background: #ad353533;
}

/* OPTIMISED FOR PHONES */
@media screen and (max-height: 850px) and (max-width: 451px) {
  .beAnActivist {
    margin: 10px 0px;
  }
}