/* .theSectionTextLeft {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #3a3b3b;
  margin: 5px auto;
  width: 60%;
  display: block;
  padding: 10px 0px;
} */
.theSectionTextLeft {
  font-size: 26px;
}

.theSectionTextCenter {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #3a3b3b;
  text-align: center;
  margin: 10px auto;
  display: block;
  width: 60%;
}

.theSectionTextFAQ {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #3a3b3b;
  margin: 5px auto;
  width: 100%;
  display: block;
  padding: 10px 0px;
}

/* OPTIMISED FOR IPAD PRO */
@media screen and (height: 1366px) and (width: 1024px) {
  .theSectionTextCenter {
    font-size: 24px;
  }

  .theSectionTextLeft {
    font-size: 20px;
  }
}

/* OPTIMISED FOR PHONES */
@media screen and (max-height: 850px) and (max-width: 451px) {
  .theSectionTextCenter {
    width: 95%;
    font-size: 16px;
  }

  .theSectionTextLeft {
    font-size: 18px;
  }
}
