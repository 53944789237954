@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');

.InsightsHome * {
	padding: 0;
	margin: 0;
	/* box-sizing: border-box; */
}

.InsightsHome {
	font-family: 'Ubuntu', sans-serif;
	background-color: #f7f8f9;
	font-size: 20px;
}

.PostContent {
	font-size: 20px;
}

.PostContent p {
	margin-bottom: 2em;
}

.PostContent h2 {
	font-weight: 300;
	font-size: 30px;
	line-height: 1.3em;
	margin-bottom: 20px
}

.About p {
	line-height: 1.5em;
	margin-left: auto;
	margin-right: auto;
	width: 85%
}

.About h2,
h1 {
	text-align: center;
}

.InsightsHome ul {
	list-style: disc;
	margin: 0 0 1.5em 3em;
}

.InsightsHome .PostBox h2 {
	font-weight: 300;
	font-size: 30px;
	line-height: 1.3em;
}

.InsightsHome .ytp-cued-thumbnail-overlay-image {
	background-size: cover;
	-moz-background-size: cover;
	-webkit-background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	position: absolute;
}

.InsightsHome h3 {
	font-size: 29px;
	margin-bottom: 20px;
	line-height: 1.2em;
	font-weight: 400;
	text-transform: none;
}

.page-hero {
	background-color: #04854f;
	color: #ffffff;
	padding-top: 80px;
	padding-right: 40px;
	padding-bottom: 80px;
	padding-left: 40px;
	width: 100%;
}

.page-hero .inside-page-hero.grid-container {
	max-width: calc(1200px - 40px - 40px);
}

.page-hero h1,
.page-hero h2,
.page-hero h3,
.page-hero h4,
.page-hero h5,
.page-hero h6 {
	color: #ffffff;
}


.container.grid-container {
	width: auto;
}

.grid-container {
	margin-left: auto;
	margin-right: auto;
	max-width: 1200px;
}



.site-content .content-area {
	width: 70%
}

.containers.right-sidebar .site-main {
	margin-left: 0;
}

.separate-containers .site-main {
	margin: 30px;
}

.separate-containers .site-main {
	margin: 20px;
}

.separate-containers.both-right .site-main,
.separate-containers.right-sidebar .site-main {
	margin-left: 0;
}

.separate-containers .site-main {
	margin: 30px;
}

.separate-containers .site-main {
	margin: 20px;
}

.separate-containers .site-main>.generate-columns-container {
	margin-bottom: 0;
}

.one-container .site-main>:last-child,
.separate-containers .site-main>:last-child {
	margin-bottom: 0;
}

.generate-columns-container:not(.masonry-container) {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
}

.post-image-above-header .no-featured-image-padding .inside-article .post-image {
	margin-top: -40px;
}

.post-image-above-header .inside-article .featured-image,
.post-image-above-header .inside-article .post-image {
	margin-top: -40px;
	margin-bottom: 2em;
}

.post-image-aligned-center .featured-image,
.post-image-aligned-center .post-image {
	text-align: center;
}

.no-featured-image-padding .post-image {
	margin-left: -40px;
	margin-right: -40px;
}


.InsightsHome a {
	color: #01a370;
}

.InsightsHome a,
.InsightsHome a:focus,
.InsightsHome a:hover,
.InsightsHome a:visited {
	text-decoration: none;
}

.InsightsHome a,
.InsightsHome button,
.InsightsHome input {
	transition: color .1s ease-in-out, background-color .1s ease-in-out;
}

.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
	max-width: 100%;
	height: auto;
}

.InsightsHome img {
	height: auto;
	max-width: 100%;
}

.entry-header,
.site-content {
	word-wrap: break-word;
}

.entry-title a {
	color: #222222;
}


.entry-meta {
	color: #595959;
	font-size: 85%;
	line-height: 1.5;
}

/* .byline, */
.entry-header,
.entry-header .comments-link,
.entry-header .tags-links,
.group-blog .byline,
.single .byline {
	display: inline;
}

.entry-content:not(:first-child),
.entry-summary:not(:first-child),
.page-content:not(:first-child) {
	margin-top: 2em;
}

.entry-content>p:last-child,
.entry-summary>p:last-child,
.page-content>p:last-child,
.read-more-container,
.taxonomy-description p:last-child {
	margin-bottom: 0;
}



.comments-link,
.tags-links {
	display: block;
}

.entry-meta {
	position: relative;
	bottom: 0 !important;
}

.entry-meta .gp-icon {
	margin-right: 0.6em;
	opacity: .7;

}

.gp-icon {
	display: inline-flex;
	align-self: center;
}

.gp-icon svg {
	height: 1em;
	width: 1em;
	top: 0.125em;
	position: relative;
	fill: currentColor;
}

.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	-webkit-clip-path: inset(50%);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important;
}

.is-right-sidebar {
	width: 30%;
}

.separate-containers .inside-right-sidebar,
.separate-containers .inside-left-sidebar {
	margin-top: 30px;
	margin-bottom: 30px;
}

.sidebar .widget,
.page-header,
.widget-area .main-navigation,
.site-main>* {
	margin-bottom: 30px;
}

.sidebar .widget {
	background-color: #ffffff;
}

.sidebar .widget,
.footer-widgets .widget {
	font-size: 17px;
}

.widget-title {
	margin-bottom: 30px;
	font-size: 20px;
	line-height: 1.5;
	font-weight: 400;
	text-transform: none;
}

.footer-widgets .widget :last-child,
.sidebar .widget :last-child {
	margin-bottom: 0;
}

.sidebar .widget,
.page-header,
.widget-area .main-navigation,
.site-main>* {
	margin-bottom: 30px;
}

.widget-area .widget {
	padding: 40px;
}

.site-content {
	display: flex;
}

.generate-columns.grid-50,
.grid-sizer.grid-50 {
	width: 50%;
}

.generate-columns {
	box-sizing: border-box;
	margin-bottom: 30px;
	padding-left: 30px;
}

.generate-columns .inside-article {
	height: 100%;
	box-sizing: border-box;
}

.separate-containers .inside-article,
.separate-containers .comments-area,
.separate-containers .page-header,
.one-container .container,
.separate-containers .paging-navigation,
.inside-page-header {
	background-color: #ffffff;
}

.separate-containers .comments-area,
.separate-containers .inside-article,
.separate-containers .page-header,
.separate-containers .paging-navigation {
	padding: 40px;
}

@media (max-width:768px) {
	.InsightsHome h1 {
		font-size: 31px;
	}

	.InsightsHome body:not(.post-image-aligned-center) .featured-image,
	.InsightsHome body:not(.post-image-aligned-center) .inside-article .featured-image,
	.InsightsHome body:not(.post-image-aligned-center) .inside-article .post-image {
		margin-right: 0;
		margin-left: 0;
		float: none;
		text-align: center
	}

	.load-more {
		display: block;
		text-align: center;
		margin-bottom: 0
	}

}


@media (min-width:768px) and (max-width:1024px) {

	.generate-columns.tablet-grid-50,
	.grid-sizer.tablet-grid-50 {
		width: 50%
	}
}

@media (max-width:767px) {
	.generate-columns-activated .generate-columns-container {
		margin-left: 0;
		margin-right: 0
	}

	.generate-columns-container .generate-columns,
	.generate-columns-container>* {
		padding-left: 0
	}

	.generate-columns-container .page-header {
		margin-left: 0
	}

	.generate-columns.mobile-grid-100,
	.grid-sizer.mobile-grid-100 {
		width: 100%
	}

	.generate-columns-container>.paging-navigation {
		margin-left: 0
	}
}

@media (max-width: 768px) {

	.is-left-sidebar.sidebar,
	.is-right-sidebar.sidebar {
		width: auto;
		order: initial;
	}
}

@media (max-width: 768px) {
	.container .site-content .content-area {
		width: auto
	}
}

@media (max-width: 768px) {
	.site-content {
		flex-direction: column;
	}
}