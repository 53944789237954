.thankyouImg {
  border: 1px solid rgba(58, 59, 59, 0.3);
  box-sizing: border-box;
  border-radius: 5px;
  max-width: 80%;
  display: block;
  margin: 20px auto;
}

.socialMediaDiv {
  background: #ffffff;
  border: 1px solid rgba(58, 59, 59, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30%;
  margin: 20px auto;
  padding: 10px 50px;
}

.TYsocialsImages {
  max-height: 50px;
  margin: 0px 5px;
}

/* OPTIMISED FOR PHONES */
@media screen and (max-height: 850px) and (max-width: 451px) {
  .socialMediaDiv {
    width: 90%;
    padding: 10px 20px;
    margin-top: 10px;
  }

  .thankyouImg {
    margin-top: 10px;
  }
}
