.sectionHeader {
  font-style: normal;
  font-weight: bold;
  color: black;
  font-size: 28px;
  background-color: rgba(244, 211, 94, 0.3);
  font-weight: 700;
  width: 60%;
  line-height: 50px;
  letter-spacing: -0.165px;
  margin: 50px auto 10px auto;
  color: rgba(58, 59, 59, 1);
  border-radius: 5px;
  display: block;
  text-align: center;
}

/* OPTIMISED FOR IPAD PRO */
@media screen and (height: 1366px) and (width: 1024px) {
  .sectionHeader {
    font-size: 32px;
  }
}

/* OPTIMISED FOR PHONES */
@media screen and (max-height: 850px) and (max-width: 451px) {
  .sectionHeader {
    width: 95%;
    font-size: 24px;
    margin: 30px auto 10px auto;
  }
}
