.EsrRating {
  background: #ffffff;
  border: 1px solid rgba(58, 59, 59, 0.1);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  /* width: 60%; */
  margin: 10px auto;
}

.fullRating {
  width: 95%;
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
}

.ratingRightSide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ratingLeftSide {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  height: 100%;
}

.ratingTopLeft {
  display: flex;
  margin-top: 5px;
}

.EsrRatingIcon {
  font-family: "Nunito Sans";
  color: #3a3b3b;
  height: 100%;
  font-weight: bold;
  width: 100px;
  text-align: center;
  border-radius: 2.5px;
  margin: 0px 10px;
}

.esrTrafficLight {
}

.esrTextSelector {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #3a3b3b;
  margin: 10px 0px;
  width: 90%;
}

.ratingTypeText {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #3a3b3b;
  margin: 0px;
  font-weight: bold;
  text-decoration: underline;
}

/* OPTIMISED FOR IPAD PRO */
@media screen and (height: 1366px) and (width: 1024px) {
  .esrTextSelector {
    font-size: 24px;
  }

  .ratingTypeText {
    font-size: 24px;
  }
}

/* OPTIMISED FOR PHONES */
@media screen and (max-height: 850px) and (max-width: 451px) {
  .EsrRating {
    width: 95%;
  }

  .fullRating {
  }

  .EsrRatingIcon {
    width: 60px;
  }

  .esrTrafficLight {
    min-height: 130px;
  }

  .esrTextSelector {
    width: 100%;
    font-size: 16px;
  }

  .ratingTypeText {
    font-size: 16px;
  }
}
