.InvestmentTools a {
	transition: font-size 0.2s !important;
	padding: 0;
	margin-left: auto;
	margin-right: auto;
	width: 160px;
	height: 50px;
	align-items: center;
	display: flex;
	justify-content: center;
	font-weight: 700;
}

.InvestmentTools a:hover {
	font-size: 1.6rem !important;
}