.burgerIcon {
  opacity: 1;
  height: 50px;
  width: 50px;
  color: white;
  justify-content: center;
  display: inline-flex;
  align-items: center;
}

.burgerIcon:hover {
  opacity: 0.5;
  cursor: pointer;
}

.burgerList {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.burgerText {
  font-family: "Nunito Sans";
  color: #3a3b3b;
  margin: 0px 0px 0px 10px;
  padding: 5px 80px 5px 0px;
  text-decoration: none;
  font-size: 30px;
}

.burgerText:hover {
  background-color: lightgray;
  cursor: pointer;
}

/* OPTIMISED FOR PHONES */
@media screen and (max-height: 850px) and (max-width: 451px) {
  .burgerIcon {
    height: 40px;
    width: 40px;
  }

  .burgerText {
    padding: 5px 15px 5px 0px;
    font-size: 18px;
  }
}