.nextStepsBox {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.nextStepsRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 10px;
}

.nsImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.nextStepsImageContainer {
    width: 10%;
}

.nextStepsTextContainer {
    width: 90%;
}

  /* OPTIMISED FOR IPADS */
  @media screen and (max-height: 1366px) and (min-height: 851px) and (max-width: 1024px) and (min-width: 452px) {
    .nextStepsImageContainer {
        width: 20%;
    }
    
    .nextStepsTextContainer {
        width: 80%;
    }
  }

/* OPTIMISED FOR PHONES */
@media screen and (max-height: 850px) and (max-width: 451px) {
    .nextStepsBox {
        width: 90%;
    }

    .nextStepsImageContainer {
        width: 30%;
    }
    
    .nextStepsTextContainer {
        width: 70%;
    }
  }