.pensionImagesHolder {
  display: flex;
  justify-content: center;
}

.pensionImages2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 880px;
  margin: 10px 0px;
}

.pensionLink2 {
  height: 200px;
  width: 200px;
  margin: 10px;
}

.pensionImageAlt {
  width: 200px;
  height: 200px;
  opacity: 0.8;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
  background-color: white;
}

.pensionImageAlt:hover {
  opacity: 1;
}

.providerNotHereDiv2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  background-color: white;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
  opacity: 0.8;
}

.providerNotHereDiv2:hover {
  opacity: 1;
}

.providerNotHereText2 {
  font-family: "Nunito Sans";
  margin: 0px;
  text-align: center;
  font-size: 30px;
  text-decoration: none;
}

  /* OPTIMISED FOR IPAD PRO */
  @media screen and (height: 1366px) and (width: 1024px) {
    .pensionImages2 {
      width: 1000px;
      margin: 30px 0px;
    }

    .pensionImageAlt {
      width: 220px;
      height: 220px;
    }
  
    .pensionLink2 {
      width: 220px;
      height: 220px;
      margin: 5px;
    }
  
    .providerNotHereDiv2 {
      width: 220px;
      height: 220px;
    }
  }

/* OPTIMISED FOR PHONES */
@media screen and (max-height: 850px) and (max-width: 451px) {
  .pensionImages2 {
    margin-top: 0px;
    width: 400px;
  }

  .pensionImageAlt {
    width: 110px;
    height: 110px;
  }

  .pensionLink2 {
    width: 110px;
    height: 110px;
    margin: 5px;
  }

  .providerNotHereDiv2 {
    width: 110px;
    height: 110px;
  }

  .providerNotHereText2 {
      font-size: 20px;
  }

}
