.pageHeader {
  font-family: "Nunito Sans";
  background-color: rgba(60, 163, 121, 1);
  color: white;
  font-size: 38px;
  text-align: center;
  margin: 0px;
  padding: 5px;
  border-radius: 2.5px;
  font-weight: bold;
  margin: 10px 0px 30px 0px;
}

/* OPTIMISED FOR PHONES */
@media screen and (max-height: 850px) and (max-width: 451px) {
  .pageHeader {
    margin: 0px 0px 10px 0px;
    font-size: 28px;
  }
}
